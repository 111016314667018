<script setup>
import { computed } from "vue";
const props = defineProps({
  prefix: {
    type: String,
    default: "icon",
  },
  name: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: "unset",
  },
  width: {
    type: [String, Number],
    default: "24",
  },
  height: {
    type: [String, Number],
    default: "24",
  },
});

const symbolId = computed(() => `#${props.prefix}-${props.name}`);
</script>

<template>
  <svg aria-hidden="true" :width="width" :height="height">
    <use :href="symbolId" :color="color" :fill="color" />
  </svg>
</template>
