<script setup>
import { watch } from "vue";
import { RouterView, useRoute } from "vue-router";
import NavBar from "@components/NavBar/NavBar.vue";
import FooterLinkWithLegalSection from "@components/Footers/FooterLinkWithLegalSection/FooterLinkWithLegalSection.vue";

import { useHubspotTracking } from "@/mixins/useHubspotTracking";

import { init as initGoAffPRo, trackPageView } from "goaffpro-client";
useHubspotTracking();

// goAffPro
const { VITE_APP_GO_AFF_PRO_PUBLIC_TOKEN } = import.meta.env;
initGoAffPRo(VITE_APP_GO_AFF_PRO_PUBLIC_TOKEN);
const route = useRoute();
watch(route, function () {
  trackPageView();
});
</script>

<template>
  <NavBar />

  <router-view v-slot="{ Component }">
    <Suspense>
      <component :is="Component" />
    </Suspense>
  </router-view>

  <FooterLinkWithLegalSection />
</template>

<style src="./style.scss" lang="scss"></style>
